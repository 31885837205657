import { Analytics } from '@analytics';
import GreenTick from '@assets/images/tip_direct/GreenTick.svg';
import LightningIcon from '@assets/images/tip_direct/LightningBolt.png';
import UpSell_BannerImage from '@assets/images/tip_direct/UpSell_BannerImage.jpg';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import CustomizableModal from '../../Common/CustomizableModal';
import messages from './messages';

const useStyles = makeStyles({
  title: {
    fontSize: '16px!important',
    fontWeight: `600!important`,
    color: '#000!important',
    textAlign: 'center',
    marginTop: '20px!important',
    marginBottom: '10px!important'
  },
  modalStyle: {
    '& .MuiPaper-root': {
      borderRadius: '18px'
    }
  },
  bodyContainer: {
    color: '#1e1e1e',
    padding: '20px'
  },
  bodyTitle: {
    fontSize: '21px',
    marginTop: 0,
    marginBottom: '8px'
  },
  bodyDesc: {
    fontSize: '16px',
    marginTop: 0,
    marginBottom: '12px',
    maxWidth: '500px'
  },
  includesBlockTitle: {
    fontSize: '14px',
    marginBottom: '12px',
    marginTop: '12px'
  },
  featureList: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: 0
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  listIcon: {
    width: '17px',
    height: '17px'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '16px'
  },
  lightningIcon: {
    width: '20px',
    height: '25px',
    marginRight: '10px'
  },
  bannerImage: {
    width: '100%'
  },
  tryButton: {
    width: '100%',
    backgroundColor: '#6997ff',
    color: '#fff',
    paddingTop: '17px',
    paddingBottom: '17px',
    fontSize: '16px',
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: '#6982ff'
    }
  },
  allPlansButton: {
    width: '100%',
    border: 'solid 1px #bdbdbd',
    color: '#000',
    paddingTop: '17px',
    paddingBottom: '17px',
    fontSize: '16px',
    borderRadius: '6px'
  }
});

const ProPlanModal = ({ isOpen, onClose, user }) => {
  const intl = useIntl();
  const classes = useStyles();

  const features = {
    essentialFeatures: intl.formatMessage(messages.essentialFeatures),
    reviewCollection: intl.formatMessage(messages.reviewCollection),
    resourceCollection: intl.formatMessage(messages.resourceCollection),
    guideTipSharing: intl.formatMessage(messages.guideTipSharing),
    nfcCards: intl.formatMessage(messages.nfcCards),
    customerService: intl.formatMessage(messages.customerService)
  };

  const modalBody = () => {
    return (
      <>
        <img className={classes.bannerImage} src={UpSell_BannerImage} alt="Tip Direct Example" />

        <div className={classes.bodyContainer}>
          <h2 className={classes.bodyTitle}>{intl.formatMessage(messages.bodyTitle)}</h2>
          <p className={classes.bodyDesc}>{intl.formatMessage(messages.bodyDesc)}</p>
          <h3 className={classes.includesBlockTitle}>
            {intl.formatMessage(messages.includesBlockTitle)}
          </h3>
          <ul className={classes.featureList}>
            {Object.keys(features).map((feature, index) => (
              <li key={index} className={classes.listItem}>
                <img className={classes.listIcon} src={GreenTick} alt="Green Tick" />
                {features[feature]}
              </li>
            ))}
          </ul>
          <div className={classes.buttonGroup}>
            <Button
              className={classes.tryButton}
              color="primary"
              onClick={() => {
                Analytics.track('Try Pro for Free clicked');
                window.open(
                  `https://${user.region.toLowerCase()}.tip.direct/settings/pricingTable`,
                  '_blank'
                );
              }}>
              <img className={classes.lightningIcon} src={LightningIcon} alt="Lightning" />
              <span>{intl.formatMessage(messages.tryProForFree)}</span>
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <CustomizableModal
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage(messages.title)}
      titleStyle={classes.title}
      modalStyle={classes.modalStyle}
      body={modalBody()}
      withHeader={true}
      withBody={true}
      withFooter={false}
      bodyPadding={false}
      handleHeaderCloseButton={onClose}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user?.user
});

export default connect(mapStateToProps)(ProPlanModal);
