import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import PreviewFlowIcon from '../../../assets/images/onboarding/complete/Eye.svg';
import PersonalQRIcon from '../../../assets/images/onboarding/complete/QR.svg';
import PrintOutIcon from '../../../assets/images/onboarding/complete/pdf.svg';
import { StyleBreakpoints } from '../../../utils/constants';
import messages from '../messages';
import SetupReviewsIcon from './../../../assets/images/onboarding/ReviewLinks.svg';
import WelcomeIcon from './../../../assets/images/onboarding/Step1_Hand.svg';
import AdditionalInfoIcon from './../../../assets/images/onboarding/Step2_Tick.svg';
import {
  default as GetPaidIcon,
  default as MoneyIcon
} from './../../../assets/images/onboarding/Step3_MoneyBag.svg';
import CongratsIcon from './../../../assets/images/onboarding/Step4_Congrats.svg';
import OrderCardsIcon from './../../../assets/images/onboarding/complete/Box.svg';
import InviteGuidesIcon from './../../../assets/images/onboarding/complete/Invite.svg';
//import TippingLinkIcon from './../../../assets/images/onboarding/TippingLink.svg';
import GuideGalleryIcon from './../../../assets/images/onboarding/complete/GuideGallery.svg';
import TippingLinkIcon from './../../../assets/images/onboarding/complete/Link.svg';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: 'auto',
    minHeight: '80px',
    padding: '20px 22px 13px 21px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff'
  },
  icon: {
    width: '35px',
    marginRight: '-5px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  altIconStyle: {
    width: '22px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#000'
  },
  boldText: {
    fontWeight: 'bold'
  },
  textContainer: {
    marginLeft: '10px'
  },
  altCardLayout: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '45px',
    paddingRight: '45px',
    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      paddingLeft: '25px',
      paddingRight: '25px'
    }
  },
  altBoldTextLayout: {
    fontSize: '14px',
    display: 'block'
  },
  whiteBackground: {
    backgroundColor: '#ffffff'
  },
  gridLayout: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '10px'
  },
  gridLayoutText: {
    display: 'grid',
    gridTemplateRows: 'auto auto'
  }
}));

const TextIconInformationBox = ({
  card,
  noBackgroundCircle = false,
  columnLayout = false,
  whiteBackground = false,
  grid = false
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const InfoBoxIcon = {
    Welcome: WelcomeIcon,
    AdditionalInfo: AdditionalInfoIcon,
    NearlyThere: MoneyIcon,
    Congratulations: CongratsIcon,
    PersonalTipping: TippingLinkIcon,
    InviteGuides: InviteGuidesIcon,
    SetupReviews: SetupReviewsIcon,
    OrderCards: OrderCardsIcon,
    GetPaid: GetPaidIcon,
    NoTipsYet: WelcomeIcon,
    PreviewTippingFlow: PreviewFlowIcon,
    PersonalQR: PersonalQRIcon,
    PrintOut: PrintOutIcon,
    GuideGallery: GuideGalleryIcon
  };

  const InfoBoxBoldText = {
    Welcome: intl.formatMessage(messages.welcomeBoldText),
    AdditionalInfo: intl.formatMessage(messages.additionalInfoBoldText),
    NearlyThere: intl.formatMessage(messages.nearlyThereBoldText),
    Congratulations: intl.formatMessage(messages.congratulationsBoldText),
    PersonalTipping: intl.formatMessage(messages.personalTippingBoldText),
    InviteGuides: intl.formatMessage(messages.inviteGuidesBoldText),
    SetupReviews: intl.formatMessage(messages.setupReviewsBoldText),
    OrderCards: intl.formatMessage(messages.orderCardsBoldText),
    GetPaid: intl.formatMessage(messages.getPaidBoldText),
    NoTipsYet: intl.formatMessage(messages.noTipsYetBoldText),
    PreviewTippingFlow: intl.formatMessage(messages.previewTippingFlowBoldText),
    PersonalQR: intl.formatMessage(messages.personalQRBoldText),
    PrintOut: intl.formatMessage(messages.printOutBoldText),
    GuideGallery: intl.formatMessage(messages.guideGalleryBoldText)
  };
  const InfoBoxDescription = {
    Welcome: ` - ${intl.formatMessage(messages.welcomeDescription)}`,
    AdditionalInfo: ` - ${intl.formatMessage(messages.additionalInfoDescription)}`,
    NearlyThere: ` - ${intl.formatMessage(messages.nearlyThereDescription)}`,
    Congratulations: `${intl.formatMessage(messages.congratulationsDescription)}`,
    PersonalTipping: ` - ${intl.formatMessage(messages.personalTippingDescription)}`,
    InviteGuides: ` - ${intl.formatMessage(messages.inviteGuidesDescription)}`,
    SetupReviews: ` - ${intl.formatMessage(messages.setupReviewsDescription)}`,
    OrderCards: ` - ${intl.formatMessage(messages.orderCardsDescription)}`,
    GetPaid: `- ${intl.formatMessage(messages.getPaidDescription)}`,
    NoTipsYet: ` - ${intl.formatMessage(messages.noTipsYetDescription)}`,
    PreviewTippingFlow: ` - ${intl.formatMessage(messages.previewTippingFlowDescription)}`,
    PersonalQR: intl.formatMessage(messages.personalQRDescription),
    PrintOut: ` - ${intl.formatMessage(messages.printOutDescription)}`,
    GuideGallery: ` - ${intl.formatMessage(messages.guideGalleryDescription)}`
  };

  return (
    <div
      className={`${classes.container} ${columnLayout ? classes.altCardLayout : ''} ${
        whiteBackground ? classes.whiteBackground : ''
      } 
        ${grid ? classes.gridLayout : ''}`}>
      <div>
        <img
          src={InfoBoxIcon[card]}
          className={noBackgroundCircle ? classes.altIconStyle : classes.icon}
          alt=""
        />
      </div>
      <div className={classes.textContainer}>
        <div className={`${classes.text} ${grid ? classes.gridLayoutText : ''}`}>
          <span className={`${classes.boldText} ${columnLayout ? classes.altBoldTextLayout : ''}`}>
            {InfoBoxBoldText[card]}
          </span>
          {InfoBoxDescription[card]}
        </div>
      </div>
    </div>
  );
};

export default TextIconInformationBox;
