import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';
import monaSansBlackWide from '../../../assets/fonts/Mona-Sans-BlackWide.js';
import monaSansSemiBoldWide from '../../../assets/fonts/Mona-Sans-SemiBoldWide.js';
import DefaultProfile from '../../../assets/images/DefaultProfile.jpg';
import SafeAndSecure from '../../../assets/images/payment/Safe&Secure.png';
import GradientRectangle from '../../../assets/images/RectangleGradient.png';
import TipDirectLogo from '../../../assets/images/tip_direct/TipDirectLogo.png';
import { regionalURLs } from '../../../utils/constants';

// Generate QR code URL based on the user's region
const generateQrURL = (userId, region) => {
  return (regionalURLs[region] || regionalURLs.US) + `t/g/${userId}`;
};

// Generate QR code image element
const generateQrImage = async qrURL => {
  try {
    const qrCode = await QRCode.toDataURL(qrURL);
    const qrImg = new Image();
    qrImg.src = qrCode;
    return qrImg;
  } catch (err) {
    console.error('Failed to generate QR code:', err);
    return null;
  }
};

// Add QR code image to the PDF
const addQrCodeToPDF = (doc, qrImg) => {
  if (!qrImg) return;
  const qrImageX = (doc.internal.pageSize.getWidth() - 125) / 2;
  doc.addImage(qrImg, 'png', qrImageX, 118, 125, 125);
};

// Add user avatar to the PDF
const addUserAvatarToPDF = async (doc, avatarUrl) => {
  const x = 131;
  const y = 28;
  const width = 54;
  const height = 54;

  if (!avatarUrl) {
    doc.addImage(DefaultProfile, 'jpg', x, y, width, height);
    return;
  }

  // Utilising a canvas here allows for clipping the corners of the image and rendering the avatar as a circle
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = 300;
  canvas.height = 300;

  const avatarImage = new Image();
  avatarImage.crossOrigin = 'anonymous';
  avatarImage.src = avatarUrl;

  await new Promise(resolve => {
    avatarImage.onload = () => {
      ctx.beginPath();
      ctx.arc(150, 150, 150, 0, Math.PI * 2);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(avatarImage, 0, 0, canvas.width, canvas.height);
      resolve();
    };
  });

  const avatarImageData = canvas.toDataURL('image/png', 1.0);

  doc.addImage(avatarImageData, 'png', x, y, width, height);
};

//Add company logo to the pdf
const addCompanyLogoToPDF = async (doc, companyLogo) => {
  if (!companyLogo) {
    return;
  }
  const logoSrc = window.location.protocol + '//' + window.location.host + '/' + companyLogo;
  const logo = await new Promise(resolve => {
    const img = new Image();
    img.src = logoSrc;
    img.onload = () => resolve(img);
  });

  //Original aspect ratio of the image
  const aspectRatio = logo.width / logo.height;
  const maxWidth = 66.15;
  const maxHeight = 37.8;

  //Maintains aspect ratio while resizing and ensures image doesn't overflow in either axis
  let logoWidth, logoHeight;
  if (logo.width > logo.height) {
    logoWidth = maxWidth;
    logoHeight = logoWidth / aspectRatio;

    if (logoHeight > maxHeight) {
      logoHeight = maxHeight;
      logoWidth = maxHeight * aspectRatio;
    }
  } else {
    logoHeight = maxHeight;
    logoWidth = logoHeight * aspectRatio;

    if (logoWidth > maxWidth) {
      logoWidth = maxWidth;
      logoHeight = maxWidth / aspectRatio;
    }
  }

  const x = 14.35 + (66.15 - logoWidth) / 2;
  const y = 238.35 + (37.8 - logoHeight) / 2;
  doc.addImage(logo, 'png', x, y, logoWidth, logoHeight);
};

// Add static images to the PDF
const addStaticImagesToPDF = doc => {
  const gradientX = (doc.internal.pageSize.getWidth() - 198) / 2;
  const gradientY = (doc.internal.pageSize.getHeight() - 284) / 2;
  doc.addImage(GradientRectangle, gradientX, gradientY, 198, 284);
  doc.addImage(SafeAndSecure, 86, 240, 106.75, 37.8);
  doc.addImage(TipDirectLogo, 158, 13, 40.25, 7.7);
};

// Add user description to the PDF
const addTextToPDF = doc => {
  doc.setFont('Mona-Sans-BlackWide', 'normal');
  doc.setFontSize(66);
  doc.text('Tip & Review', 18.55, 52, {
    align: 'left',
    maxWidth: 160
  });

  doc.setFont('Mona-Sans-SemiBoldWide', 'normal');
  doc.setFontSize(30);
  doc.text('All gratuities and reviews are greatly appreciated.', 18.55, 102, {
    align: 'left',
    maxWidth: 160
  });
};

// Generate QR Code Printout
export const generateQrPrintout = async (user, region) => {
  if (!user || !user.userId || !user.profile) {
    console.error('Invalid user object');
    return;
  }

  const { profile, companyLogo } = user;
  const qrURL = generateQrURL(user.userId, region);
  const doc = new jsPDF();

  monaSansBlackWide;
  monaSansSemiBoldWide;
  doc.getFontList();

  const qrImg = await generateQrImage(qrURL);
  addQrCodeToPDF(doc, qrImg);
  addUserAvatarToPDF(doc, profile.avatarUrl);
  addStaticImagesToPDF(doc);
  await addCompanyLogoToPDF(doc, companyLogo);
  addTextToPDF(doc);

  doc.save('TripAdmit-Tip-Direct-QR-Code-A4.pdf');
};
